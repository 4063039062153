import React, { useState } from "react";

import { Router } from "@reach/router";

import { ModalProvider } from "react-modal-hook";
import { CloudinaryContext } from "cloudinary-react";

import Welcome from "pages/Welcome";
import Map from "pages/Map";
import About from "pages/About";

export default function Account() {
  const [user, setUser] = useState();

  const userTest = {
    id: "5eb1916867c5999c253222fa",
    name: "Test",
    phone: "7867534324",
    orders: [],
  };

  return (
    <CloudinaryContext cloudName="cloud9apex">
      <ModalProvider>
        {!user ? (
          <Welcome setUser={setUser} />
        ) : (
          <Router>
            <Map path="/" user={user} />
            <About path="/about" />
          </Router>
        )}
      </ModalProvider>
    </CloudinaryContext>
  );
}
