import React, { useState, useRef } from 'react';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';

import MapGL, { GeolocateControl, Marker, Source, Layer } from 'react-map-gl';
import Geocoder from 'react-map-gl-geocoder';

import mask from '@turf/mask';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Columns, Column, Button, Level, Left, Right, Item } from 'components/ui/bulma';

import Navbar from 'components/ui/Navbar';
import Order from 'components/Order';

import deliveryArea from 'config/deliveryArea';

const token = 'pk.eyJ1IjoiYW5kcmVqczE5NzkiLCJhIjoiY2s4ZXg3M3hxMDBtaDNkbjZwMGl1ZGNkMCJ9.lfRQSV9ls7UYOQgG4zJSSg';

export default function Map({ user }) {
	const mapRef = useRef();
	const geoCoder = useRef();
	const [ error, setError ] = useState();

	const [ location, setLocation ] = useState();
	const [ order, setOrder ] = useState();
	const [ viewport, setViewport ] = useState({
		latitude: 38.9,
		longitude: -77.04,
		zoom: 11,
		minZoom: 11,
		maxZoom: 20
	});

	const handleGeocoderViewportChange = (viewport) => {
		const geocoderDefaultOverrides = { transitionDuration: 1000 };

		const newViewport = {
			...viewport,
			latitude: viewport.latitude - 0.001
		};

		return handleViewportChange({
			...newViewport,
			...geocoderDefaultOverrides
		});
	};

	const handleViewportChange = (viewport) => {
		setViewport({ ...viewport });
	};

	const _onViewportChange = (viewport) => setViewport({ ...viewport });

	const geoFilter = (item) =>
		item.context
			.map((i) => i.id.split('.').shift() === 'region' && i.text === 'District of Columbia')
			.reduce((acc, cur) => acc || cur);

	const handleOnResult = ({ result }) => {
		if (!booleanPointInPolygon(result.center, deliveryArea)) {
			setError('Please order to a Washington, DC address within our delivery area!');
		} else {
			setError(null);
			setLocation(result);
		}
	};

	const data = mask(deliveryArea);

	return (
		<MapGL
			{...viewport}
			width="100vw"
			height="100vh"
			ref={mapRef}
			mapStyle="mapbox://styles/andrejs1979/ck8gin8zl09us1ioih7tkmcpi"
			mapboxApiAccessToken={token}
			// onViewportChange={handleViewportChange}
		>
			<Navbar mapRef={mapRef} />
			<div className="columns is-mobile is-centered is-gapless is-clipped">
				<div
					className="column is-clipped is-12-mobile is-12-touch is-5-tablet is-3-desktop"
					style={{
						position: 'absolute',
						bottom: '0px',
						zIndex: 20
					}}
				>
					<Order location={location} user={user} order={order} setOrder={setOrder} />
				</div>
			</div>
			<Source id="my-data" type="geojson" data={data}>
				<Layer
					id="area"
					type="fill"
					paint={{
						'fill-antialias': true,
						'fill-outline-color': '#000',
						'fill-color': '#209cee',
						'fill-opacity': 0.5
					}}
				/>
				<Layer id="line" type="line" />
			</Source>

			{!order &&
			location && (
				<Marker latitude={location.center[1]} longitude={location.center[0]}>
					<FontAwesomeIcon icon="map-marker-alt" size="3x" color="#FF3366" />
				</Marker>
			)}
		</MapGL>
	);
}

const Panel = ({ mapRef, geoCoder, order, location, user, setOrder, setGifts, error }) => (
	<div className="columns is-mobile is-centered is-gapless">
		<div
			className="column is-clipped is-12-mobile is-12-touch is-5-tablet is-3-desktop"
			style={{
				justifyContent: 'center',
				alignItems: 'center',
				position: 'absolute',
				bottom: '0px',
				zIndex: 1
			}}
		>
			{!order ? (
				<div className="box has-background-light" style={{ height: 300 }}>
					<p className="title is-size-5 has-text-left">
						Greetings! <FontAwesomeIcon icon="grin" color="#ffdd57" />
					</p>

					{error ? (
						<p className="subtitle is-size-5 has-text-left has-text-danger">{error}</p>
					) : (
						<p className="subtitle is-size-5 has-text-left">
							Where would you like your art and gift delivered?
						</p>
					)}

					<Level mobile>
						<Item>
							<div ref={geoCoder} />
						</Item>
					</Level>

					<br />
					<Button block size="medium" color="danger" disabled={!location} action={() => setOrder(true)}>
						Continue
					</Button>
				</div>
			) : (
				<Order location={location} user={user} order={order} setOrder={setOrder} />
			)}
		</div>
	</div>
);
