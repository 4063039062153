import React, { useState, useEffect, useReducer } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useModal } from "react-modal-hook";
import { navigate } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import uniqBy from "lodash/uniqBy";
import sortBy from "lodash/sortBy";
import numeral from "numeral";

import { Image } from "cloudinary-react";

import Error from "components/ui/Error";
import Spinner from "components/ui/Spinner";

import { Box, Button } from "components/ui/bulma";

import reducer from "reducers/cart";

export default function Gifts({ gifts, setGifts, step, setStep }) {
  const initialState = { gifts: [], total: 0, count: 0, limit: false };
  const [view, setView] = useState("slides");
  const [state, dispatch] = useReducer(reducer, initialState);

  const { loading, data, error } = useQuery(GIFTS, { fetchPolicy: "no-cache" });

  if (loading || !data) return <Spinner />;
  if (error) return <Error error={error} />;

  return (
    <div>
      <div
        className="is-hidden-desktop has-background-white is-clipped"
        style={{ position: "relative", top: "40px", height: "100vh" }}
      >
        <div
          style={{
            height: "80vh",
            // overflowY: 'scroll',
            overflowX: "hidden",
            marginTop: 10,
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 10,
          }}
        >
          <Slides state={state} dispatch={dispatch} data={data} />
        </div>

        <nav
          className="navbar is-fixed-bottom is-hidden-desktop"
          style={{ height: "20vh" }}
        >
          <Header
            view={view}
            setView={setView}
            state={state}
            step={step}
            setStep={setStep}
          />
          <p className="is-size-6 has-text-weight-bold has-text-centered">
            Volume Discounts Up To Legal Limit
          </p>
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            <Button
              block
              size="medium"
              color="danger"
              icon="check-circle"
              disabled={state.gifts.length < 1}
              action={() => {
                setGifts(state);
                setStep(++step);
              }}
            >
              Confirm gifts
            </Button>
          </div>
        </nav>
      </div>

      <div
        className="is-hidden-mobile is-hidden-touch has-background-white is-clipped"
        style={{ position: "relative", top: "0px", height: "90vh" }}
      >
        <Header
          view={view}
          setView={setView}
          state={state}
          step={step}
          setStep={setStep}
        />
        <p className="is-size-6 has-text-weight-bold has-text-centered">
          Volume Discounts Up To Legal Limit
        </p>

        <div
          style={{
            height: "60vh",
            // overflowY: 'scroll',
            overflowX: "hidden",
            marginTop: 10,
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 10,
          }}
        >
          <Slides state={state} dispatch={dispatch} data={data} />
        </div>
        <div style={{ padding: 15 }}>
          <Button
            block
            size="medium"
            color="danger"
            icon="check-circle"
            disabled={state.gifts.length < 1}
            action={() => {
              setGifts(state);
              setStep(++step);
            }}
          >
            Confirm gifts
          </Button>
        </div>
      </div>
    </div>
  );
}

const Header = ({ state, view, setView, step, setStep }) => (
  <header className="modal-card-head">
    {/* <div>
			<p className="title is-size-4 has-text-white has-text-centered">Gift Shop</p>
		</div> */}
    <div className="has-text-left" style={{ marginRight: 10 }}>
      <FontAwesomeIcon
        icon="arrow-alt-circle-left"
        size="2x"
        color="#209cee"
        onClick={() => setStep(--step)}
      />
    </div>
    <p className="modal-card-title">{`Art: $${numeral(state.total).format(
      "0,0.00"
    )}`}</p>
    {!state.limit ? (
      state.gifts.length > 0 ? (
        <p className="modal-card-title">{`Gifts: ${state.count}`}</p>
      ) : (
        <p className="modal-card-title">Select gifts</p>
      )
    ) : (
      <p className="modal-card-title has-text-danger">Max. gifts</p>
    )}
  </header>
);

const Product = ({ item, state, dispatch, onClose }) => (
  <Box>
    <button className="delete is-medium is-pulled-right" onClick={onClose} />
    <p className="title is-4">{item.name}</p>
    <p className="subtitle">
      {item.category.subcategory}{" "}
      <span
        style={{
          color: `${
            item.subcategory === "Indica"
              ? "#126cab"
              : item.subcategory === "Sativa"
              ? "#faca18"
              : "#42bc97"
          }`,
        }}
      >
        {item.subcategory}
      </span>
    </p>

    <div className="has-text-centered">
      <Image publicId={`cloud9/${item.picture}`} />

      <div className="buttons has-addons is-centered">
        <FontAwesomeIcon
          icon="minus-square"
          size="3x"
          color={
            state.gifts.find(({ id }) => id === item.id) ? "#ff3860" : "#CCC"
          }
          onClick={() => {
            state.gifts.find(({ id }) => id === item.id) &&
              dispatch({
                type: "remove",
                payload: { item },
              });
          }}
        />
        <div
          style={{
            backgroundColor: "#000",
            fontWeight: "bold",
            color: "#FFF",
            width: "30px",
          }}
        >
          {itemQty(state && state.gifts, item) > 0 ? (
            itemQty(state && state.gifts, item)
          ) : (
            <span className="has-text-black">0</span>
          )}
        </div>
        <FontAwesomeIcon
          icon="plus-square"
          size="3x"
          color={!state.limit ? "#23d160" : "#CCC"}
          onClick={() =>
            !state.limit &&
            dispatch({
              type: "add",
              payload: { item },
            })
          }
        />
      </div>
    </div>
  </Box>
);

const Slides = ({ state, dispatch, data }) => {
  const [product, setProduct] = useState();
  const validCategories = uniqBy(
    data.categories.map(({ category, display }) => ({ category, display })),
    "display"
  );
  const sortedCategories = sortBy(validCategories, ["display"]);
  const gifts = data.categories.flatMap(category => category.gifts);

  const [showProduct, hideProduct] = useModal(
    () => (
      <div className="modal is-active">
        <div className="modal-background" />
        <div className="modal-content">
          <Product
            state={state}
            dispatch={dispatch}
            item={product}
            onClose={hideProduct}
          />
        </div>
      </div>
    ),
    [product, state, dispatch]
  );

  return (
    <div
      className="is-clipped"
      style={{
        overflowX: "hidden",
        padding: 10,
      }}
    >
      {sortedCategories.map(category => {
        const categoryGifts = gifts.filter(
          item => item.category.category === category.category
        );
        return (
          <div key={category.category}>
            {categoryGifts && categoryGifts.length > 0 && (
              <div>
                <div>
                  <br />
                  <p className="is-size-4 has-text-weight-bold">
                    {category.category}
                  </p>
                </div>

                <div
                  className="columns is-mobile"
                  style={{ overflowX: "scroll" }}
                >
                  {categoryGifts.map(item => (
                    <div className="column is-narrow" key={item.id}>
                      <div className="has-text-centered">
                        <Image
                          onClick={() => {
                            setProduct(item);
                            showProduct();
                          }}
                          publicId={`cloud9/${item.picture}`}
                          width="120"
                          // crop="scale"
                        />

                        <p className="is-size-6 has-text-weight-bold has-text-centered">
                          {item.name}
                        </p>
                        <p className="is-size-6 has-text-centered has-text-weight-bold">
                          {item.category.subcategory}
                        </p>
                        <p
                          className="is-size-6 has-text-centered has-text-weight-bold"
                          style={{
                            color: `${
                              item.subcategory === "Indica"
                                ? "#126cab"
                                : item.subcategory === "Sativa"
                                ? "#faca18"
                                : "#42bc97"
                            }`,
                          }}
                        >
                          {item.subcategory}
                        </p>
                        <div className="buttons has-addons is-centered">
                          <FontAwesomeIcon
                            icon="minus-square"
                            size="3x"
                            color={
                              state.gifts.find(({ id }) => id === item.id)
                                ? "#ff3860"
                                : "#CCC"
                            }
                            onClick={() => {
                              state.gifts.find(({ id }) => id === item.id) &&
                                dispatch({
                                  type: "remove",
                                  payload: { item },
                                });
                            }}
                          />
                          <div
                            style={{
                              backgroundColor: "#000",
                              fontWeight: "bold",
                              color: "#FFF",
                              width: "30px",
                            }}
                          >
                            {itemQty(state && state.gifts, item) > 0 ? (
                              itemQty(state && state.gifts, item)
                            ) : (
                              <span className="has-text-black">0</span>
                            )}
                          </div>
                          <FontAwesomeIcon
                            icon="plus-square"
                            size="3x"
                            color={!state.limit ? "#23d160" : "#CCC"}
                            onClick={() =>
                              !state.limit &&
                              dispatch({
                                type: "add",
                                payload: { item },
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        );
      })}

      <br className="is-hidden-desktop" />
      <br className="is-hidden-desktop" />
      <br className="is-hidden-desktop" />
      <br className="is-hidden-desktop" />
      <br className="is-hidden-desktop" />
      <br className="is-hidden-desktop" />
      <br className="is-hidden-desktop" />
    </div>
  );
};

const itemQty = (gifts, item) => {
  if (gifts) {
    const res = gifts.filter(({ id }) => id === item.id).pop();
    if (res) return res.qty;
  }
};

const GIFTS = gql`
  query Gifts {
    categories {
      id
      category
      subcategory
      pricing {
        id
        thresholdQty
        price
      }
      display
      gifts {
        id
        name
        price
        category {
          id
          category
          subcategory
          pricing {
            id
            thresholdQty
            price
          }
        }
        subcategory
        tier
        picture
      }
    }
  }
`;

const REDEEM_GIFT = gql`
  mutation RedeemGift($giftCode: String!, $gifts: [String]) {
    redeemGift(giftCode: $giftCode, gifts: $gifts) {
      id
    }
  }
`;
