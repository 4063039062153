import _ from 'lodash'

const LIMIT = 700;

export default function reducer(state, action) {
	switch (action.type) {
		case 'add':
			return add(state, action.payload);
		case 'remove':
			return remove(state, action.payload);
		default:
			throw new Error();
	}
}

const add = (state, { item }) => {
	if (getTotal(state.gifts) <= LIMIT && getTotal(state.gifts) + Number(item.price) <= LIMIT) {
		const i = state.gifts.findIndex(({ id }) => id === item.id);

		if (~i) {
			const gifts = state.gifts;
			gifts[i].qty++;

			return {
				gifts,
				count: getCount(gifts),
				total: getTotal(gifts),
				limit: getTotal(gifts) >= LIMIT || getTotal(state.gifts) + Number(item.price) >= LIMIT ? true : false
			};
		} else {
			const gifts = state.gifts.concat({ ...item, qty: 1 });

			return {
				gifts,
				count: getCount(gifts),
				total: getTotal(gifts),
				limit: getTotal(gifts) >= LIMIT ? true : false
			};
		}
	} else return state;
};

const remove = (state, { item }) => {
	const i = state.gifts.findIndex(({ id }) => id === item.id);

	if (~i) {
		const gifts = state.gifts;
		gifts[i].qty--;

		return {
			gifts: gifts.filter((item) => item.qty > 0),
			count: getCount(gifts),
			total: getTotal(gifts),
			limit: getTotal(gifts) >= LIMIT ? true : false
		};
	}

	return state;
};

const getTotal = (items) => {

	
	const total = items.reduce((acc, item) => {
		// const qty = getCount(items);
		let price;

		const { qty }=countByCategory(items).find(({subcategory})=>item.category.subcategory===subcategory)
		
		item.category.pricing.map((item) => {
			if (qty >= item.thresholdQty) price = +item.price;
		});

		return acc + price * item.qty;
	}, 0);

	return Math.round(total / 5) * 5;
};

const getCount = (items) => items.reduce((acc, item) => acc + Number(item.qty), 0);

const countByCategory = (items) =>{
	const subcategories = _.uniq(items.map( item => item.category.subcategory))

	return subcategories.map(subcategory =>
		
		({ subcategory, qty:getCount(items.filter(item => item.category.subcategory === subcategory)) })
	)
	
}