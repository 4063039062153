const firebaseConfig = {
	apiKey: 'AIzaSyBDei3M85_rPGEopsuQsLAXCJyD4l_M4Fo',
	authDomain: 'delivery-4adc3.firebaseapp.com',
	databaseURL: 'https://delivery-4adc3.firebaseio.com',
	projectId: 'delivery-4adc3',
	storageBucket: 'delivery-4adc3.appspot.com',
	messagingSenderId: '948309286770',
	appId: '1:948309286770:web:b4018f7f0f2572f999c7eb'
};

export default firebaseConfig;
