import React, { useState, useRef } from 'react';
import { navigate } from '@reach/router';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';

import { StaticMap, GeolocateControl, Marker } from 'react-map-gl';
import Geocoder from 'react-map-gl-geocoder';
import DeckGL from '@deck.gl/react';
import { GeoJsonLayer } from '@deck.gl/layers';

import mask from '@turf/mask';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import combine from '@turf/combine';
import union from '@turf/union';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Menu from 'components/ui/Menu';
import { Columns, Column, Button, Level, Left, Right, Item } from 'components/ui/bulma';

import Navbar from 'components/ui/Navbar';
import Order from 'components/Order';
import Gifts from 'components/Gifts';

export default function About() {
	return (
		<div>
			<Navbar />
			<section className="hero is-info is-fullheight-with-navbar">
				<div className="hero-body">
					<div className="container">
						<br />
						<div
							className="delete is-large is-pulled-right"
							onClick={() => navigate('/')}
							// style={{ position: 'absolute', top: 90, right: 20 }}
						/>
						<br />
						<p className="title has-text-centered">Cloud9 Art & Smoke</p>
						<p className="subtitle has-text-centered">DC's Premium Source of Art, Music & I-71 gifts!</p>
						<br />
						<p className="title is-size-4  has-text-centered">
							We love and appreciate your business and hope we put a smile on your face every delivery.
						</p>
						<p className="title is-size-4  has-text-centered">
							A portion of proceeds are donated to the local DC charities.<br />
							<FontAwesomeIcon icon="heart" size="5x" color="#ff3860" />
						</p>
					</div>
				</div>
			</section>
		</div>
	);
}
