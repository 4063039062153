import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Container, Columns, Input } from "components/ui/bulma";

import Timer from "react-compound-timer";
import { FastField, Formik, Form } from "formik";
import { object, string } from "yup";

import ReactCodeInput from "react-code-input";

import logo from "assets/logo.png";

export default function Welcome({ setUser }) {
  const [sent, setSent] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [sendCode] = useMutation(SEND_CODE);
  const [validateCode] = useMutation(VALIDATE_CODE);

  const validate = async code => {
    if (code && code.length === 4) {
      const { data } = await validateCode({ variables: { phone, code } });
      if (data.validateCode && data.validateCode)
        setUser({ ...data.validateCode, name });
    }
  };

  return (
    <section className="hero is-info is-fullheight">
      {/* <div className="hero-head has-background-light">
				<nav className="navbar is-fixed-top has-background-light">
					<Columns mobile vertical>
						<Column>
							<FontAwesomeIcon
								icon="bars"
								size="lg"
								color="black"
								onClick={() => setExtended(true)}
								style={{ margin: 20 }}
							/>
						</Column>
						<Column>
							<img src={logo} alt="logo" width="100" className="is-hidden-desktop" />
							<img src={logo} alt="logo" width="200" className="is-hidden-mobile" />
						</Column>
						<Column />
					</Columns>
				</nav>
				<Menu extendedMenu={extended} extendMenu={setExtended} />
			</div> */}
      <div className="hero-body">
        <Container>
          <Columns centered>
            <div className="column is-half-desktop is-full-mobile has-text-centered">
              <img src={logo} alt="logo" width="200" />
              <br />
              <br />
              {!sent ? (
                <div>
                  <p className="title is-size-1 has-text-centered">
                    Closed for Maintenance
                  </p>
                  <p className="subtitle is-size-3 has-text-centered">
                    Reopening Soon!
                  </p>
                  {/* <p className="title is-size-1 has-text-centered">
                    Art &amp; Initiative 71 Gifts
                  </p>
                  <p className="subtitle is-size-3 has-text-centered">
                    Curbside Pickup
                  </p> */}
                  <br />
                  <Formik
                    initialValues={{ name: "", phone: "" }}
                    // validate={validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      if (values.phone) {
                        const phone = values.phone
                          .replace("(", "")
                          .replace(")", "")
                          .replace(" ", "")
                          .replace("-", "");
                        setSent(true);
                        setPhone(phone);
                        setName(values.name);
                        await sendCode({ variables: { phone } });
                      }
                    }}
                  >
                    {({ errors, values, isSubmitting }) => (
                      <Form>
                        <div className="field is-centered">
                          <FastField
                            name="name"
                            icon="user"
                            size="large"
                            component={Input}
                            placeholder="Your name"
                          />
                        </div>
                        <div className="field has-addons has-addons-centered is-hidden-mobile">
                          <FastField
                            name="phone"
                            icon="mobile-alt"
                            size="large"
                            component={Input}
                            mask="(999) 999-9999"
                            placeholder="Mobile number..."
                            inputmode="numeric"
                            pattern="[0-9]*"
                          />

                          <div className="control">
                            <button
                              type="submit"
                              className="button is-danger is-full is-large"
                            >
                              Login
                            </button>
                          </div>
                        </div>
                        <div className="field is-hidden-desktop is-hidden-tablet">
                          <FastField
                            name="phone"
                            icon="mobile-alt"
                            size="large"
                            component={Input}
                            mask="(999) 999-9999"
                            placeholder="Mobile number..."
                            inputmode="numeric"
                            // pattern="[0-9]*"
                          />
                          <br />
                          <div className="control">
                            <button
                              disabled
                              type="submit"
                              className="button is-fullwidth is-danger is-full is-large"
                            >
                              Login
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              ) : (
                <div>
                  <p className="title has-text-centered">
                    Enter the code you just recieved
                  </p>
                  <Timer initialTime={120000} direction="backward">
                    {() => (
                      <p className="subtitle has-text-centered">
                        <Timer.Minutes /> : <Timer.Seconds />
                      </p>
                    )}
                  </Timer>

                  <ReactCodeInput
                    type="number"
                    fields={4}
                    inputMode="numeric"
                    onChange={validate}
                    {...codeProps}
                  />

                  <br />

                  <p className="subtitle is-size-5 has-text-centered">
                    <a onClick={() => setSent(false)}>
                      Didn't get the text message? Try again!
                    </a>
                  </p>
                </div>
              )}
            </div>
          </Columns>
        </Container>
      </div>

      <div className="hero-foot has-background-light">
        <div className="container has-text-centered">
          <br />

          <p>
            <FontAwesomeIcon icon="phone-alt" color="black" />{" "}
            <a
              className="title is-size-6 has-text-black"
              style={{ marginLeft: 10 }}
              href="tel:2029219888"
            >
              Need Help? Give us a call
            </a>
          </p>
          <br />
        </div>
      </div>
    </section>
  );
}

const validationSchema = object().shape({
  code: string().required("Please enter the code from the text messsage!"),
});

const SEND_CODE = gql`
  mutation SendCode($phone: String!) {
    sendCode(phone: $phone)
  }
`;

const VALIDATE_CODE = gql`
  mutation ValidateCode($phone: String!, $code: String!) {
    validateCode(phone: $phone, code: $code) {
      id
      phone
    }
  }
`;

// const CURRENT_USER = gql`
// 	query CurrentUser($phone: String!) {
// 		currentUser(phone: $phone) {
// 			id
// 			phone
// 			email
// 		}
// 	}
// `;

const codeProps = {
  inputStyle: {
    fontFamily: "monospace",
    margin: "4px",
    borderRadius: "3px",
    height: "50px",
    width: "35px",
    fontSize: "20px",
  },
};
