import React, { useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image } from "cloudinary-react";
import { format, addHours } from "date-fns";

import Spinner from "components/ui/Spinner";
import { Box, Button, Columns, Column } from "components/ui/bulma";

import MapGL, { StaticMap, Marker } from "react-map-gl";
const token =
  "pk.eyJ1IjoiYW5kcmVqczE5NzkiLCJhIjoiY2s4ZXg3M3hxMDBtaDNkbjZwMGl1ZGNkMCJ9.lfRQSV9ls7UYOQgG4zJSSg";

export default function Confirmation({ delivery, location, gifts }) {
  const mapRef = useRef();
  const [step, setStep] = useState(1);

  const [image, setImage] = useState();

  const [day, setDay] = useState();

  const [accept, setAccept] = useState();
  const [confirm, setConfirm] = useState();
  const [finish, setFinish] = useState();

  const address = `${location.address}`;

  return (
    <div className="box has-background-light">
      <div className="has-text-centered">
        <div style={{ height: "10vh" }}>
          <StaticMap
            ref={mapRef}
            latitude={location.center[1]}
            longitude={location.center[0]}
            zoom={12}
            mapStyle="mapbox://styles/andrejs1979/ck8gin8zl09us1ioih7tkmcpi"
            width="100%"
            height="100%"
            mapboxApiAccessToken={token}
          >
            <Marker
              latitude={location.center[1]}
              longitude={location.center[0]}
            >
              <FontAwesomeIcon
                icon="map-marker-alt"
                size="2x"
                color="#FF3366"
              />
            </Marker>
          </StaticMap>
        </div>
      </div>
      <br />
      <p className="title has-text-centered is-size-5-mobile">Thank you!</p>
      {/* <p className="subtitle has-text-centered is-size-6">We'll text your gift code after confirmation.</p> */}

      <p className="is-6 has-text-centered">
        Where: <strong>{address}</strong>
      </p>
      <p className="is-6 has-text-centered">
        When:{" "}
        <strong>{format(new Date(delivery), "MM/dd/yyyy hh:mm a")}</strong>
      </p>
      <br />
      <Box>
        {/* <p className="title is-5">Your order:</p> */}
        <p className="title is-6">
          Art Print
          <span className="is-pulled-right">${gifts.total}</span>
        </p>
        {/* {Number(gifts.total) > 100 && 
        <p className="subtitle is-6">
          Discount
          <span className="is-pulled-right">-$10</span>
        </p>
        } */}
        <p className="subtitle is-6">
          Gifts
          <span className="is-pulled-right">FREE</span>
        </p>
        <p className="subtitle is-6">
          Delivery
          <span className="is-pulled-right">FREE</span>
        </p>
        <hr />
        <p className="title is-6">
          Total
          <span className="is-pulled-right">
            {/* ${Number(gifts.total) >100? Number(gifts.total)-10:Number(gifts.total)} */}
            ${gifts.total}
          </span>
        </p>
      </Box>
      <p className="has-text-centered">Pay by CASH at pickup</p>
      <p className="has-text-centered">
        I am 21 years of age and I will have a valid photo ID during the pickup.
      </p>

      <p className="title is-size-7 has-text-centered">
        <br />
        <FontAwesomeIcon icon="phone-alt" />{" "}
        <a href="tel:2029219888" className="has-text-black">
          Need Help? Give us a call 202-921-9888
        </a>
      </p>
    </div>
  );
}
