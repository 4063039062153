import React, { useRef, useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image } from "cloudinary-react";

import Spinner from "components/ui/Spinner";
import { Button } from "components/ui/bulma";

export default function Items({ setItem, step, setStep, setOrder }) {
  const { data, loading, error } = useQuery(ITEMS);

  if (!data && loading) return <Spinner />;

  return (
    <div style={{ height: 630 }}>
      <div className="has-text-left">
        <FontAwesomeIcon
          icon="arrow-alt-circle-left"
          size="3x"
          color="#209cee"
          onClick={() => setOrder(false)}
        />
      </div>
      <br />
      <div className="box">
        <p className="title is-size-5">
          Choose Artwork, then select GIFTS for Pickup:
        </p>
        <div className="columns is-mobile">
          <div className="column has-text-centered">
            <Image
              publicId={`cloud9/${data.items[0].picture}`}
              height="120"
              crop="scale"
              onClick={() => {
                setItem(data.items[0].id);
                setStep(++step);
              }}
            />

            <Button
              block
              color="danger"
              action={() => {
                setItem(data.items[0].id);
                setStep(++step);
              }}
            >
              ADD TO <FontAwesomeIcon icon="cart-arrow-down" color="white" />
            </Button>
          </div>

          <div className="column has-text-centered">
            <Image
              publicId={`cloud9/${data.items[1].picture}`}
              height="120"
              crop="scale"
              onClick={() => {
                setItem(data.items[1].id);
                setStep(++step);
              }}
            />
            <Button
              block
              color="danger"
              action={() => {
                setItem(data.items[1].id);
                setStep(++step);
              }}
            >
              ADD TO <FontAwesomeIcon icon="cart-arrow-down" color="white" />
            </Button>
          </div>
        </div>

        <div className="columns is-mobile">
          <div className="column has-text-centered">
            <Image
              publicId={`cloud9/${data.items[2].picture}`}
              height="120"
              crop="scale"
              onClick={() => {
                setItem(data.items[2].id);
                setStep(++step);
              }}
            />

            <Button
              block
              color="danger"
              action={() => {
                setItem(data.items[2].id);
                setStep(++step);
              }}
            >
              ADD TO <FontAwesomeIcon icon="cart-arrow-down" color="white" />
            </Button>
          </div>
          <div className="column has-text-centered">
            <Image
              publicId={`cloud9/${data?.items[3]?.picture}`}
              height="120"
              crop="scale"
              onClick={() => {
                setItem(data.items[3].id);
                setStep(++step);
              }}
            />

            <Button
              block
              color="danger"
              action={() => {
                setItem(data.items[3].id);
                setStep(++step);
              }}
            >
              ADD TO <FontAwesomeIcon icon="cart-arrow-down" color="white" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

const ITEMS = gql`
  query Items {
    items {
      id
      name
      price
      picture
    }
  }
`;
