import './App.sass';
import React, { useEffect } from 'react';

import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseAppAuth } from 'services/firebase';

import analytics from 'react-segment';
import Drift from 'react-driftjs';

import Account from 'components/Account';

import Error from 'components/ui/Error';
import Spinner from 'components/ui/Spinner';

import Icons from 'utils/icons';

const auth = firebaseAppAuth;

analytics.default.load(process.env.SEGMENT);

Icons();

export default function App() {
	const [ user, loading, error ] = useAuthState(firebaseAppAuth);

	useEffect(
		() => {
			!loading && !user && auth.signInAnonymously();
		},
		[ loading, user ]
	);

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;

	const client = new ApolloClient({
		cache: new InMemoryCache(),
		link: new HttpLink({
			headers: {
				authorization: user ? user.uid : null
			},
			uri: '/.netlify/functions/server'
		})
	});

	return (
		<ApolloProvider client={client}>
			<Account />
			{/* <Drift appId="525778n766cc" /> */}
		</ApolloProvider>
	);
}
