import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@reach/router';

export default function SidePanel({ extendedMenu, extendMenu }) {
	return <ExtendedMenu extendedMenu={extendedMenu} extendMenu={extendMenu} />;
}

function ExtendedMenu({ extendedMenu, extendMenu }) {
	return (
		<div
			className={`has-background-info navigation-view is-overlay ${extendedMenu ? 'is-active' : ''}`}
			id="myNavigationView"
			style={{ position: 'fixed', zIndex: 100 }}
		>
			<a
				className="delete is-large is-pulled-right"
				onClick={() => extendMenu(!extendedMenu)}
				style={{ margin: 20 }}
			/>

			<Menu>
				<MenuItem extended name="Shop" icon="tachometer-alt" route="/" />
				<MenuItem extended name="About" icon="tachometer-alt" route="/about" />
			</Menu>
		</div>
	);
}

function Menu({ title, children }) {
	return (
		<aside className="menu is-dark">
			{title && <p className="menu-label">{title}</p>}
			<ul className="menu-list">{children}</ul>
		</aside>
	);
}
function MenuItem({ extended, name, icon, route }) {
	if (extended) {
		return (
			<li>
				<Link to={route}>
					<p className="is-size-4 has-text-weight-semibold has-text-white">{name}</p>
				</Link>
			</li>
		);
	} else
		return (
			<li className="has-text-centered">
				<Link to={route}>
					<FontAwesomeIcon icon={icon} color="black" size="lg" />
					<p className="is-size-7 has-text-weight-semibold">{name}</p>
				</Link>
			</li>
		);
}
