import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Menu from 'components/ui/Menu';
import { Level, Left, Right, Item } from 'components/ui/bulma';

import logo from 'assets/logo.png';

export default function Navbar() {
	const [ extended, setExtended ] = useState(false);
	return (
		<div>
			<nav className="navbar is-fixed-top is-hidden-desktop is-hidden-tablet">
				<Menu extendedMenu={extended} extendMenu={setExtended} />
				<Level mobile color="info">
					<Left>
						<FontAwesomeIcon
							icon="stream"
							size="lg"
							color="white"
							onClick={() => setExtended(true)}
							style={{ margin: 20 }}
						/>
					</Left>
					<Item>
						<img src={logo} alt="logo" width="100" />
					</Item>
					<Right>
						<a href="tel:2029219888">
							<FontAwesomeIcon icon="phone" size="lg" color="white" style={{ margin: 20 }} />
						</a>
					</Right>
				</Level>
			</nav>
			<nav
				className="is-hidden-mobile is-hidden-touch"
				style={{ position: 'fixed', zIndex: 100, width: '100vw' }}
			>
				<Menu extendedMenu={extended} extendMenu={setExtended} />
				<Level mobile color="info">
					<Left>
						<FontAwesomeIcon
							icon="stream"
							size="lg"
							color="white"
							onClick={() => setExtended(true)}
							style={{ margin: 20 }}
						/>
					</Left>
					<Item>
						<img src={logo} alt="logo" width="100" />
					</Item>
					<Right>
						<a href="tel:2029219888">
							<FontAwesomeIcon icon="phone" size="lg" color="white" style={{ margin: 20 }} />
						</a>
					</Right>
				</Level>
			</nav>
		</div>
	);
}
