export default {
	type: 'Feature',
	geometry: {
		type: 'Polygon',
		coordinates: [
			[
				[ -77.085192, 38.912144 ],
				[ -77.082204, 38.91235 ],
				[ -77.07776, 38.912306 ],
				[ -77.073165, 38.912247 ],
				[ -77.069554, 38.912365 ],
				[ -77.069213, 38.903889 ],
				[ -77.056784, 38.899803 ],
				[ -77.056608, 38.897944 ],
				[ -77.055825, 38.897212 ],
				[ -77.053357, 38.897182 ],
				[ -77.052613, 38.895932 ],
				[ -77.047948, 38.895783 ],
				[ -77.04114, 38.892191 ],
				[ -77.039533, 38.892177 ],
				[ -77.039495, 38.900154 ],
				[ -77.033576, 38.900154 ],
				[ -77.033727, 38.89225 ],
				[ -77.006023, 38.892088 ],
				[ -77.006023, 38.887658 ],
				[ -77.031931, 38.887614 ],
				[ -77.03195, 38.883212 ],
				[ -77.029113, 38.882035 ],
				[ -77.024764, 38.879002 ],
				[ -77.008271, 38.879001 ],
				[ -77.001485, 38.879029 ],
				[ -77.001531, 38.881069 ],
				[ -76.99578, 38.881015 ],
				[ -76.99578, 38.88255 ],
				[ -76.993368, 38.882532 ],
				[ -76.99346, 38.888218 ],
				[ -76.990886, 38.889355 ],
				[ -76.991153, 38.896638 ],
				[ -76.986593, 38.898453 ],
				[ -76.98772, 38.902275 ],
				[ -76.998151, 38.906598 ],
				[ -76.995974, 38.909582 ],
				[ -76.998053, 38.910366 ],
				[ -76.998606, 38.911251 ],
				[ -77.001057, 38.91296 ],
				[ -77.008699, 38.912871 ],
				[ -77.008721, 38.921467 ],
				[ -77.012644, 38.92149 ],
				[ -77.014048, 38.920986 ],
				[ -77.017839, 38.920522 ],
				[ -77.018102, 38.922657 ],
				[ -77.018041, 38.923741 ],
				[ -77.018317, 38.924337 ],
				[ -77.019702, 38.925742 ],
				[ -77.020223, 38.927375 ],
				[ -77.020086, 38.927804 ],
				[ -77.018631, 38.928078 ],
				[ -77.017727, 38.928948 ],
				[ -77.018983, 38.934229 ],
				[ -77.018906, 38.937279 ],
				[ -77.018582, 38.937726 ],
				[ -77.018877, 38.942445 ],
				[ -77.042872, 38.942361 ],
				[ -77.043827, 38.942005 ],
				[ -77.045717, 38.943096 ],
				[ -77.047375, 38.943091 ],
				[ -77.049587, 38.943545 ],
				[ -77.050125, 38.944516 ],
				[ -77.051595, 38.946529 ],
				[ -77.051415, 38.948738 ],
				[ -77.052582, 38.950087 ],
				[ -77.052958, 38.95169 ],
				[ -77.054572, 38.952187 ],
				[ -77.055597, 38.953576 ],
				[ -77.057413, 38.954531 ],
				[ -77.057348, 38.955667 ],
				[ -77.057872, 38.956099 ],
				[ -77.05761, 38.956557 ],
				[ -77.05676, 38.956684 ],
				[ -77.056563, 38.9576 ],
				[ -77.05555, 38.958744 ],
				[ -77.05578, 38.960789 ],
				[ -77.055948, 38.961444 ],
				[ -77.084955, 38.961608 ],
				[ -77.100638, 38.949309 ],
				[ -77.100492, 38.948476 ],
				[ -77.08651, 38.937961 ],
				[ -77.08651, 38.937374 ],
				[ -77.091166, 38.933214 ],
				[ -77.091184, 38.929997 ],
				[ -77.090843, 38.928657 ],
				[ -77.090332, 38.927312 ],
				[ -77.089675, 38.921967 ],
				[ -77.088969, 38.920016 ],
				[ -77.08892, 38.916322 ],
				[ -77.088263, 38.914901 ],
				[ -77.086023, 38.913632 ],
				[ -77.085192, 38.912144 ]
			]
		]
	}
};
