import React, { useRef, useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addDays,
  addMinutes,
  setHours,
  differenceInMinutes,
  startOfHour,
} from "date-fns";

import { Box, Button, Columns, Column } from "components/ui/bulma";

const slots = [
  {
    title: "Today",
    subtitle: "Afternoon",
    start: startOfHour(setHours(Date.now(), 10)),
    slot: "10:00 am ",
  },
  {
    title: "Today",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(Date.now(), 10)), 30),
    slot: "10:30 am ",
  },
  {
    title: "Today",
    subtitle: "Afternoon",
    start: startOfHour(setHours(Date.now(), 11)),
    slot: "11:00 am ",
  },
  {
    title: "Today",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(Date.now(), 11)), 30),
    slot: "11:30 am ",
  },
  {
    title: "Today",
    subtitle: "Afternoon",
    start: startOfHour(setHours(Date.now(), 12)),
    slot: "12:00 pm ",
  },
  {
    title: "Today",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(Date.now(), 12)), 30),
    slot: "12:30 pm ",
  },
  {
    title: "Today",
    subtitle: "Afternoon",
    start: startOfHour(setHours(Date.now(), 13)),
    slot: "1:00 pm ",
  },

  {
    title: "Today",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(Date.now(), 13)), 30),
    slot: "1:30 pm ",
  },
  {
    title: "Today",
    subtitle: "Afternoon",
    start: startOfHour(setHours(Date.now(), 14)),
    slot: "2:00 pm ",
  },
  {
    title: "Today",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(Date.now(), 14)), 30),
    slot: "2:30 pm ",
  },
  {
    title: "Today",
    subtitle: "Afternoon",
    start: startOfHour(setHours(Date.now(), 15)),
    slot: "3:00 pm ",
  },
  {
    title: "Today",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(Date.now(), 15)), 30),
    slot: "3:30 pm ",
  },
  {
    title: "Today",
    subtitle: "Afternoon",
    start: startOfHour(setHours(Date.now(), 16)),
    slot: "4:00 pm ",
  },
  {
    title: "Today",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(Date.now(), 16)), 30),
    slot: "4:30 pm ",
  },
  {
    title: "Today",
    subtitle: "Afternoon",
    start: startOfHour(setHours(Date.now(), 17)),
    slot: "5:00 pm ",
  },
  {
    title: "Today",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(Date.now(), 17)), 30),
    slot: "5:30 pm ",
  },
  {
    title: "Today",
    subtitle: "Evening",
    start: startOfHour(setHours(Date.now(), 18)),
    slot: "6:00 pm",
  },
  {
    title: "Today",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(Date.now(), 18)), 30),
    slot: "6:30 pm ",
  },
  {
    title: "Today",
    subtitle: "Evening",
    start: startOfHour(setHours(Date.now(), 19)),
    slot: "7:00 pm",
  },

  {
    title: "Tomorrow",
    subtitle: "Midday",
    start: startOfHour(setHours(addDays(Date.now(), 1), 10)),
    slot: "10:00 am",
  },
  {
    title: "Tomorrow",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(addDays(Date.now(), 1), 10)), 30),
    slot: "10:30 pm",
  },
  {
    title: "Tomorrow",
    subtitle: "Midday",
    start: startOfHour(setHours(addDays(Date.now(), 1), 11)),
    slot: "11:00 am",
  },
  {
    title: "Tomorrow",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(addDays(Date.now(), 1), 11)), 30),
    slot: "11:30 pm",
  },
  {
    title: "Tomorrow",
    subtitle: "Midday",
    start: startOfHour(setHours(addDays(Date.now(), 1), 12)),
    slot: "12:00 pm",
  },
  {
    title: "Tomorrow",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(addDays(Date.now(), 1), 12)), 30),
    slot: "12:30 pm",
  },
  {
    title: "Tomorrow",
    subtitle: "Midday",
    start: startOfHour(setHours(addDays(Date.now(), 1), 13)),
    slot: "1:00 pm",
  },
  {
    title: "Tomorrow",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(addDays(Date.now(), 1), 13)), 30),
    slot: "1:30 pm ",
  },
  {
    title: "Tomorrow",
    subtitle: "Midday",
    start: startOfHour(setHours(addDays(Date.now(), 1), 14)),
    slot: "2:00 pm",
  },
  {
    title: "Tomorrow",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(addDays(Date.now(), 1), 14)), 30),
    slot: "2:30 pm ",
  },
  {
    title: "Tomorrow",
    subtitle: "Afternoon",
    start: startOfHour(setHours(addDays(Date.now(), 1), 15)),
    slot: "3:00 pm",
  },
  {
    title: "Tomorrow",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(addDays(Date.now(), 1), 15)), 30),
    slot: "3:30 pm ",
  },
  {
    title: "Tomorrow",
    subtitle: "Afternoon",
    start: startOfHour(setHours(addDays(Date.now(), 1), 16)),
    slot: "4:00 pm",
  },
  {
    title: "Tomorrow",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(addDays(Date.now(), 1), 16)), 30),
    slot: "4:30 pm ",
  },
  {
    title: "Tomorrow",
    subtitle: "Afternoon",
    start: startOfHour(setHours(addDays(Date.now(), 1), 17)),
    slot: "5:00 pm",
  },
  {
    title: "Tomorrow",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(addDays(Date.now(), 1), 17)), 30),
    slot: "5:30 pm ",
  },
  {
    title: "Tomorrow",
    subtitle: "Evening",
    start: startOfHour(setHours(addDays(Date.now(), 1), 18)),
    slot: "6:00 pm",
  },
  {
    title: "Tomorrow",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(addDays(Date.now(), 1), 18)), 30),
    slot: "6:30 pm ",
  },
  {
    title: "Tomorrow",
    subtitle: "Evening",
    start: startOfHour(setHours(addDays(Date.now(), 1), 19)),
    slot: "7:00 pm",
  },
  {
    title: "Tomorrow",
    subtitle: "Afternoon",
    start: addMinutes(startOfHour(setHours(addDays(Date.now(), 1), 19)), 30),
    slot: "7:30 pm ",
  },
  {
    title: "Tomorrow",
    subtitle: "Evening",
    start: startOfHour(setHours(addDays(Date.now(), 1), 20)),
    slot: "8:00 pm",
  },
];

// const isAvailableNow = isWithinInterval(Date.now(), {
// 	start: setHours(Date.now(), 13),
// 	end: setHours(Date.now(), 19)
// });

export default function Delivery({
  delivery,
  setDelivery,
  step,
  setStep,
  confirm,
  setConfirm,
}) {
  const [day, setDay] = useState();

  return (
    <div style={{ height: 500 }}>
      <div className="has-text-left">
        <FontAwesomeIcon
          icon="arrow-alt-circle-left"
          size="3x"
          color="#209cee"
          onClick={() => setStep(--step)}
        />
      </div>
      <br />
      <div className="box has-background-light">
        <p className="title is-size-4-mobile">
          Book Store Pickup at 1512 U Street NW
        </p>

        {/* {isAvailableNow && (
					<div style={{ marginBottom: 10 }}>
						<Button
							block
							// outlined={day !== 'Today'}
							color="danger"
							// active={day === 'Today'}
							action={() => {
								setDelivery(Date.now());
								setStep(++step);
							}}
							disabled={!isAvailableNow}
						>
							Get it now
						</Button>
					</div>
				)} */}
        <div style={{ marginBottom: 10 }}>
          <Button
            block
            outlined={day !== "Today"}
            color="info"
            // active={day === 'Today'}
            action={() => setDay("Today")}
            disabled={
              !slots.filter(
                slot =>
                  slot.title === "Today" &&
                  differenceInMinutes(slot.start, Date.now()) > 30
              ).length > 0
            }
          >
            Today
          </Button>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Button
            block
            outlined={day !== "Tomorrow"}
            color="info"
            active={day === "Tomorrow"}
            action={() => setDay("Tomorrow")}
          >
            Tomorrow
          </Button>
        </div>

        <div className="select is-medium is-fullwidth">
          <select
            value={delivery}
            onChange={({ target }) => setDelivery(target.value)}
          >
            <option value={null}>Select meetup time</option>
            {slots
              .filter(slot => slot.title === day)
              .map(
                slot =>
                  differenceInMinutes(slot.start, Date.now()) > 30 && (
                    <option value={slot.start} key={slot.start}>
                      {slot.slot}
                    </option>
                  )
              )}
          </select>
        </div>
        <br />
        <br />
        <Button
          block
          color="danger"
          size="medium"
          icon="check-circle"
          action={() => {
            setConfirm(true);
            setStep(++step);
          }}
          disabled={!delivery}
        >
          Book Store Pickup
        </Button>
      </div>
    </div>
  );
}
