import { library } from '@fortawesome/fontawesome-svg-core';

import {
	faPhone,
	faCreditCard,
	faMapMarkerAlt,
	faMobileAlt,
	faUnlock,
	faShoppingCart,
	faArrowAltCircleLeft,
	faArrowCircleLeft,
	faStream,
	faGift,
	faPhoneAlt,
	faGrin,
	faCartArrowDown,
	faArrowCircleRight,
	faCheckCircle,
	faPlusCircle,
	faMinusCircle,
	faSlidersH,
	faListAlt,
	faHeart,
	faPlusSquare,
	faMinusSquare,
	faUser
} from '@fortawesome/free-solid-svg-icons';

export default () => {
	library.add(
		faCreditCard,
		faMapMarkerAlt,
		faMobileAlt,
		faUnlock,
		faShoppingCart,
		faArrowAltCircleLeft,
		faPhone,
		faArrowCircleLeft,
		faStream,
		faGift,
		faPhoneAlt,
		faGrin,
		faCartArrowDown,
		faArrowCircleRight,
		faCheckCircle,
		faPlusCircle,
		faMinusCircle,
		faSlidersH,
		faListAlt,
		faHeart,
		faPlusSquare,
		faMinusSquare,
		faUser
	);
};
