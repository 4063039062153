import React, { useState, useEffect, useReducer } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { navigate } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Image } from "cloudinary-react";

import Error from "components/ui/Error";
import Spinner from "components/ui/Spinner";

import { Box, Button } from "components/ui/bulma";
import Items from "./order/Items";

import reducer from "reducers/cart";

export default function Gifts({ gifts, setGifts }) {
  const initialState = { gifts: gifts || [], total: 0, count: 0, limit: false };
  const [view, setView] = useState("slides");
  const [confirm, setConfirm] = useState();
  const [state, dispatch] = useReducer(reducer, initialState);

  const { loading, data, error } = useQuery(GIFTS);

  if (loading || !data) return <Spinner />;
  if (error) return <Error error={error} />;

  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <Header view={view} setView={setView} state={state} />
        <section className="modal-card-body">
          {view === "slides" ? (
            <Slides state={state} dispatch={dispatch} data={data} />
          ) : (
            <List state={state} dispatch={dispatch} data={data} />
          )}
        </section>

        <footer className="modal-card-foot">
          <Button
            block
            color="danger"
            icon="check-circle"
            // disabled={giftsLeft !== 0}
            action={() => setConfirm(true)}
          >
            Confirm gifts
          </Button>
        </footer>
      </div>
    </div>
  );
}

const Header = ({ state, view, setView }) => (
  <header className="modal-card-head has-background-info">
    {!state.limit ? (
      state.gifts.length > 0 ? (
        <p className="modal-card-title has-text-white">{`Art: $${state.total} Gifts: ${state.count}`}</p>
      ) : (
        <p className="modal-card-title has-text-white">Select gifts</p>
      )
    ) : (
      <p className="modal-card-title has-text-danger">Max. gifts reached</p>
    )}

    <div
      className="buttons are-small has-addons is-right"
      style={{ marginLeft: 20 }}
    >
      <button className="button" onClick={() => setView("slides")}>
        <FontAwesomeIcon icon="sliders-h" color="black" size="lg" />
      </button>
      <button className="button" onClick={() => setView("list")}>
        <FontAwesomeIcon icon="list-alt" color="black" size="lg" />
      </button>
    </div>
  </header>
);

const Slides = ({ state, dispatch, data }) => (
  <div>
    {data.categories &&
      data.categories.map(({ id, category, subcategory }) => (
        <div key={id}>
          {data.gifts && data.gifts.length > 0 && (
            <div>
              <p className="title">{category}</p>

              <div
                className="columns is-mobile"
                style={{ overflowX: "scroll" }}
              >
                {data.gifts.map(item => (
                  <div
                    className="column is-5-mobile is-2-desktop"
                    key={item.id}
                  >
                    <div>
                      <div>
                        <Image
                          publicId={`cloud9/${item.picture}`}
                          width="100"
                          crop="scale"
                        />
                        <span className="tag is-info is-rounded">
                          {itemQty(state && state.gifts, item) || 0}
                        </span>
                      </div>
                      <br />
                      <p className="title is-size-6">{item.name}</p>
                      <p className="subtitle is-size-6">{subcategory}</p>
                      <div className="buttons are-small has-addons">
                        <button
                          className="button is-success"
                          onClick={() =>
                            dispatch({
                              type: "add",
                              payload: { item },
                            })
                          }
                          disabled={state.limit}
                        >
                          <FontAwesomeIcon icon="plus-circle" />
                        </button>

                        <button
                          className="button is-danger"
                          onClick={() =>
                            dispatch({
                              type: "remove",
                              payload: { item },
                            })
                          }
                          disabled={state.gifts.findIndex(
                            ({ id }) => id === item.id
                          )}
                        >
                          <FontAwesomeIcon icon="minus-circle" />
                        </button>
                      </div>
                    </div>
                    <br />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
  </div>
);

const List = ({ state, dispatch, data }) => (
  <div>
    {data.categories &&
      data.categories.map(({ id, category, subcategory }) => (
        <div key={id}>
          {data.gifts.filter(item => item.category.id === id).length > 0 && (
            <div>
              <p className="subtitle has-text-centered">{category}</p>
              <p className="title has-text-centered">{subcategory}</p>
            </div>
          )}
          {data.gifts
            .filter(item => item.category.id === id)
            .map(item => (
              <div key={item.id}>
                <Box>
                  <p className="title is-size-5">{item.name}</p>
                  <Image publicId={`cloud9/${item.picture}`} />

                  <div className="buttons has-addons is-centered">
                    <button
                      className="button is-success"
                      onClick={() =>
                        dispatch({
                          type: "add",
                          payload: { item },
                        })
                      }
                      // disabled={
                      // 	Number(data.orderByGiftCode.item.tier) ===
                      // 	gifts.length ? (
                      // 		true
                      // 	) : (
                      // 		false
                      // 	)
                      // }
                    >
                      <FontAwesomeIcon icon="plus-circle" />
                      Add
                    </button>
                    <button
                      className="button is-danger"
                      onClick={() =>
                        dispatch({
                          type: "remove",
                          payload: { item },
                        })
                      }
                      disabled={!state.gifts.includes(item.id)}
                    >
                      <FontAwesomeIcon icon="minus-circle" />
                      Remove
                    </button>
                  </div>
                </Box>
                <br />
              </div>
            ))}
          <br />
          <br />
        </div>
      ))}
  </div>
);

const itemQty = (gifts, item) => {
  if (gifts) {
    const res = gifts.filter(({ id }) => id === item.id).pop();
    if (res) return res.qty;
  }
};

const GIFTS = gql`
  query Gifts {
    categories {
      id
      category
      subcategory
      pricing {
        id
        thresholdQty
        price
      }
    }
    gifts {
      id
      name
      price
      category {
        id
        category
        subcategory
        pricing {
          id
          thresholdQty
          price
        }
      }
      tier
      picture
    }
  }
`;

const REDEEM_GIFT = gql`
  mutation RedeemGift($giftCode: String!, $gifts: [String]) {
    redeemGift(giftCode: $giftCode, gifts: $gifts) {
      id
    }
  }
`;
