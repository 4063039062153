import React, { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";

import { formatISO } from "date-fns";

import Items from "components/order/Items";
import Delivery from "components/order/Delivery";
import Location from "components/order/Location";
import Gifts from "components/order/Gifts";
import Confirmation from "components/order/Confirmation";
import Thanks from "components/order/Thanks";

export default function Order({ user, order, setOrder }) {
  const [step, setStep] = useState(0);
  const [item, setItem] = useState();
  const [delivery, setDelivery] = useState();
  const [location, setLocation] = useState({
    id: 1,
    name: "Pizza Meetup",
    address: "1512 U Street NW DC, 20009",
    center: [-77.034832, 38.917142],
  });
  const [gifts, setGifts] = useState();
  const [confirm, setConfirm] = useState();

  const [createOrder] = useMutation(CREATE_ORDER);

  useEffect(() => {
    if (confirm && item && delivery && gifts && location && user) {
      const orderProps = {
        address: `${location.address ? location.address : ""} ${
          location.text ? location.text : ""
        }`,
        name: user.name,
        phone: user.phone,
        item,
        deliveryDateTime: formatISO(new Date(delivery)),
        orderGifts: gifts.gifts.map(gift => ({
          gift: `${gift.name} - ${gift.subcategory} - ${gift.category.category} - ${gift.category.subcategory}`,
          qty: gift.qty,
        })),
        amount: gifts.total,
      };

      try {
        createOrder({ variables: { orderProps } });
      } catch (error) {
        console.log(error);
      }
    }
  }, [item, delivery, gifts, location, user, confirm, createOrder]);

  switch (step) {
    case 0:
      return (
        <Items
          item={item}
          setItem={setItem}
          step={step}
          setStep={setStep}
          setOrder={setOrder}
        />
      );
    case 1:
      return (
        <Gifts
          gifts={gifts}
          setGifts={setGifts}
          step={step}
          setStep={setStep}
        />
      );
    case 2:
      return (
        <Delivery
          delivery={delivery}
          setDelivery={setDelivery}
          step={step}
          setStep={setStep}
          confirm={confirm}
          setConfirm={setConfirm}
        />
      );

    case 3:
      return (
        <Thanks
          location={location}
          delivery={delivery}
          gifts={gifts}
          step={step}
          setStep={setStep}
        />
      );
    default:
      return null;
  }
}

const CREATE_ORDER = gql`
  mutation CreateOrder($orderProps: OrderProps) {
    createOrder(orderProps: $orderProps) {
      id
    }
  }
`;
